<script>
import { electronicsDisplay, dianziyouyiLogin } from '@/api/home';
export default {
    name: 'Description',
    components:{
        Header: () => import('components/base/Header'),
    },
    data() {
        return {
            header:{
                mainTitle: getWord(['rebate', 'explain']),
            },

        }
    },
    props: [
        
    ],
    inject:[
        
    ],
    methods: {

    },
    mounted() {

    },
};
</script>
<template>    
    <section id="description" ref="description" :class="_TEMPLATE">
        <Header
            :mainTitle="header.mainTitle"
            :backButton=false
        />
        <content>
            <slot name="content">
                <h4>{{ getWord('title') }}</h4>
                <p>{{ getWord('content') }}</p>
            </slot>
        </content>
    </section>
</template>
<style lang='scss' scoped type="text/css">
    content {
        padding: .25rem;
        display: block;

        h4 {
            font-weight: normal;
            font-size: .36rem;
            color: #EC2829;
            margin-bottom: .15rem;
        }

        p {
            font-size: .28rem;
            color: #333333;
        }
    }    
</style>